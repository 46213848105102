/**
 * About the app
 */

export const rootURL = window.location.origin || 'https://ketacademy.web.app'
export const actionCodeSettings = {
  url: `${rootURL}/dashboard`,
  handleCodeInApp: true,
}

//User permission

const permissions = {
  AMBASSADOR: 'Ambassador', // Ambassador & Facilitators
  ADMINISTRATOR: 'HOD and/or Ex-Co Member', //Head of Department
  STUDENT: 'Student',
  CORE: 'Core Member', //Core Members
}

const roles = ['SOCIAL_MEDIA_MANAGER', 'GRAPHIC_DESIGNER']

//subject
const concentrations = [
  'Arts',
  'Science',
  'Technical',
  'Commercial',
  'Vocational',
]

//sub systems
const subSystems = ['Francophone', 'Anglo-saxon']

//levels
const levels = {
  Francophone: ['Baccaluereat', 'Capieme'],
  'Anglo-saxon': ['Ordinary', 'Advanced'],
}

const subjects = {
  // O-level subjects
  MATH: 'Mathematics',
  ENG: 'English Language',
  FRE: 'French',
  BIO: 'Biology',
  CHEM: 'Chemistry',
  PHY: 'Physics',
  HIST: 'History',
  GEO: 'Geography',
  LIT: 'Literature in English',
  ECON: 'Economics',
  COMM: 'Commerce',
  COMP: 'Computer Science',
  FMA: 'Further Mathematics',
}
/* 
=================================
* Resources
==================================
*/
const categories = {
  PDF: 'Pdf',
  YOUTUBE: 'Youtube',
}

export {
  categories,
  concentrations,
  levels,
  permissions,
  roles,
  subSystems,
  subjects,
}
