import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../utils/firebase';

function ResourceDetail() {
  const { id } = useParams();
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResource = async () => {
      const docRef = doc(firestore, 'resources', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Resource data:', docSnap.data());
        setResource(docSnap.data());
      } else {
        console.error('No such document!');
      }
      setLoading(false);
    };

    fetchResource();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!resource) {
    return (
      <div>
        <p>Resource not found. It might have been removed or the link is incorrect.</p>
        <Link to="/library" className="text-blue-500 hover:underline">
          Back to Library
        </Link>
      </div>
    );
  }

  return (
    <div>
      <h1>{resource.title}</h1>
      <p>{resource.description}</p>
      <p>Level: {resource.level}</p>
      <p>Subject: {resource.subject}</p>
      <p>Type: {resource.resourceType}</p>
      <a href={resource.resourceUrl} target="_blank" rel="noopener noreferrer">
        View Resource
      </a>
      <Link to="/library" className="text-blue-500 hover:underline">
        Back to Library
      </Link>
    </div>
  );
}

export default ResourceDetail; 