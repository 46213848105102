import {
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth'
import { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { auth } from '.'
import { Alert } from '../../components'

/*
 * Handle paswordless sign in
 */
export async function passwordlessSignin() {
  let success = false
  if (!isSignInWithEmailLink(auth, window.location.href)) return false
  if (auth.currentUser) return //this means the useeffect is running the second time
  let email = window.localStorage.getItem('KET-email-for-sign-in')

  if (!email) {
    const { inputValue } = await Alert.fire({
      title: 'Enter your email address',
      input: 'email',
      inputLabel: 'Your email address',
      inputPlaceholder: 'Enter your email address',
    })
    email = inputValue
  }

  try {
    const { user } = await signInWithEmailLink(
      auth,
      email,
      window.location.href
    )
    toast.success(
      `You have logged in successfully with ${user?.email || email}`,
      {
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      }
    )
    success = true
    window.location.href = '/dashboard'
  } catch (error) {
    toast.error(error.message)
    Alert.fire({
      icon: 'error',
      title: 'Authentication error.',
      text: `Error logging in with email <<${email}>>`,
      footer: '<a href="/">Back to Home</a>',
    })
  } finally {
    window.localStorage.removeItem('KET-email-for-sign-in')
  }
  return success
}

/*
 ** setup Providers and hooks for user
 */
const AuthUserContext = createContext({
  authUser: null,
  isLoading: true,
})

const useFirebaseAuth = function () {
  const [authUser, setAuthUser] = useState(null)
  const [userIsLoading, setUserIsLoading] = useState(true)

  const authStateChangeHandler = async (user) => {
    setUserIsLoading(true)
    setAuthUser(user)
    setUserIsLoading(false)
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChangeHandler)
    return () => unsubscribe()
  }, [])

  return {
    authUser,
    userIsLoading,
  }
}

const AuthUserProvider = function ({ children }) {
  const auth = useFirebaseAuth()
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  )
}

const useAuth = () => useContext(AuthUserContext)
export { AuthUserProvider, useAuth }
