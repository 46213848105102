import React, { useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import Footer from '../../../components/Footer'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/styles.css'

import FullScreen from 'yet-another-react-lightbox/plugins/fullscreen'
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Video from "yet-another-react-lightbox/plugins/video";
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Video from "yet-another-react-lightbox/plugins/video";
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

import {} from '@fortawesome/free-solid-svg-icons'
import Banner from '../../../components/Banner'


import meetup1Image from '../../../assets/images/gallery/meetup1.JPG'
import meetup2Image from '../../../assets/images/gallery/meetup2.JPG'
import meetup3Image from '../../../assets/images/gallery/meetup3.JPG'
import meetup4Image from '../../../assets/images/gallery/meetup4.JPG'
import orientationImage from '../../../assets/images/gallery/orientation.jpg'
import outreachImage from '../../../assets/images/gallery/outreach1.jpeg'
//import outreachVideo from '../../../assets/images/gallery/outreach10.mp4'
import outreachImage11 from '../../../assets/images/gallery/outreach11.jpg'
import outreachImage12 from '../../../assets/images/gallery/outreach12.jpg'
import outreachImage13 from '../../../assets/images/gallery/outreach13.jpg'
import outreachImage14 from '../../../assets/images/gallery/outreach14.jpg'
import outreachImage15 from '../../../assets/images/gallery/outreach15.jpg'
import outreachImage16 from '../../../assets/images/gallery/outreach16.jpg'
import outreachImage17 from '../../../assets/images/gallery/outreach17.jpg'
import outreachImage2 from '../../../assets/images/gallery/outreach2.jpeg'
import outreachImage3 from '../../../assets/images/gallery/outreach3.jpeg'
import outreachImage4 from '../../../assets/images/gallery/outreach4.jpeg'
import outreachImage5 from '../../../assets/images/gallery/outreach7.jpg'
import outreachImage6 from '../../../assets/images/gallery/outreach6.jpg'
import outreachImage7 from '../../../assets/images/gallery/outreach7.jpg'
import outreachImage8 from '../../../assets/images/gallery/outreach8.jpg'
import talkImage1 from '../../../assets/images/gallery/talk.jpeg'
import talkImage2 from '../../../assets/images/gallery/talk2.jpg'

import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Video from 'yet-another-react-lightbox/plugins/video';

// Array of image objects for the gallery
const images = [
  // Remove the original mock data
  // {
  //   src: 'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg',
  //   thumbnail:
  //     'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg',
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 174,
  //   caption: 'After Rain (Jeshu John - designerspics.com)',
  // },
  // {
  //   src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
  //   thumbnail:
  //     'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg',
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  //   tags: [
  //     { value: 'Ocean', title: 'Ocean' },
  //     { value: 'People', title: 'People' },
  //   ],
  //   caption: 'Boats (Jeshu John - designerspics.com)',
  // },
  // {
  //   src: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
  //   thumbnail:
  //     'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg',
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  // },

  // Add your imported images here
  {
    src: meetup1Image,
    thumbnail: meetup1Image,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Meetup 1',
  },
  {
    src: meetup2Image,
    thumbnail: meetup2Image,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Meetup 2',
  },
  {
    src: meetup3Image,
    thumbnail: meetup3Image,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Meetup 3',
  },
  {
    src: meetup4Image,
    thumbnail: meetup4Image,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Meetup 4',
  },
  {
    src: orientationImage,
    thumbnail: orientationImage,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Orientation',
  },
  {
    src: outreachImage,
    thumbnail: outreachImage,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 1',
  },
  {
    src: outreachImage11,
    thumbnail: outreachImage11,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 11',
  },
  {
    src: outreachImage12,
    thumbnail: outreachImage12,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 12',
  },
  {
    src: outreachImage13,
    thumbnail: outreachImage13,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 13',
  },
  {
    src: outreachImage14,
    thumbnail: outreachImage14,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 14',
  },
  {
    src: outreachImage15,
    thumbnail: outreachImage15,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 15',
  },
  {
    src: outreachImage16,
    thumbnail: outreachImage16,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 16',
  },
  {
    src: outreachImage17,
    thumbnail: outreachImage17,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 17',
  },
  {
    src: outreachImage2,
    thumbnail: outreachImage2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 2',
  },
  {
    src: outreachImage3,
    thumbnail: outreachImage3,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 3',
  },
  {
    src: outreachImage4,
    thumbnail: outreachImage4,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 4',
  },
  {
    src: outreachImage5,
    thumbnail: outreachImage5,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 5',
  },
  {
    src: outreachImage6,
    thumbnail: outreachImage6,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 6',
  },
  {
    src: outreachImage7,
    thumbnail: outreachImage7,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 7',
  },
  {
    src: outreachImage8,
    thumbnail: outreachImage8,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Outreach 8',
  },
  {
    src: talkImage1,
    thumbnail: talkImage1,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Talk 1',
  },
  {
    src: talkImage2,
    thumbnail: talkImage2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Talk 2',
  },
  // Add more images as needed
]

// Main component for the gallery page
const GalleryPage = () => {
  const [index, setIndex] = useState(-1) // State to track the index of the currently selected image

  // Function to handle image click, setting the index of the clicked image
  const handleClick = (index, item) => setIndex(index) // index: number, item: CustomImage
  const captionsRef = React.useRef(null) // Reference for the captions plugin

  return (
    <div id="root">
      <Banner title={'How We are.'} />
      <main>
        <section>
          <Gallery
            className={'gallery'}
            onClick={handleClick}
            images={images}
            enableImageSelection={false}
            thumbnailImageComponent={ImageComponent}
          />
          <Lightbox
            plugins={[Captions, Zoom, FullScreen, Slideshow, Video]}
            slides={images}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 5,
            }}
            on={{
              click: () => {
                (captionsRef.current?.visible
                  ? captionsRef.current?.hide
                  : captionsRef.current?.show)?.()
              },
            }}
          />
        </section>
      </main>
      <Footer />
    </div>
  )
}

// Custom component for rendering gallery images
const ImageComponent = (props) => {
  return (
    <div className="image-container">
      <img
        className="gallery-image"
        {...props.imageProps} // Spread image properties
        height={400} // Set image height
        alt={props?.caption || 'Gallery image'} // Provide meaningful alt text
        loading="lazy" // Enable lazy loading
      />
      <div className="image-caption">{props?.caption}</div>
    </div>
  )
}

export default GalleryPage // Export the GalleryPage component as default
