import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const DashboardSideNavBar = function ({
  sideBarShowing,
  toggleSidebar,
  linkItems,
}) {
  const sideBarRef = useRef(null)

  const toggleOnFocusOut = useCallback(
    (event) => {
      if (
        !event.target.matches('div.toggle, div.toggle *') && // click should not be on hamburger
        sideBarRef.current !== event.target && // click should be out of sidebar
        sideBarShowing // sidebar must be visible
      ) {
        toggleSidebar() // All Good. Toggle
      }
    },
    [sideBarShowing, toggleSidebar]
  ) // Include sideBarShowing and toggleSidebar in the dependency array

  useEffect(() => {
    document.addEventListener('click', toggleOnFocusOut)
    return () => document.removeEventListener('click', toggleOnFocusOut)
  }, [toggleOnFocusOut]) // Only include toggleOnFocusOut in the dependency array

  return (
    <aside
      className={`sidebar ${sideBarShowing ? 'show' : 'hide'}`}
      ref={sideBarRef}
    >
      <ul className="side_nav">
        {linkItems.map(({ text, link, faIcon }, index) => (
          <li key={index}>
            <NavLink
              to={link}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <FontAwesomeIcon icon={faIcon} />
              {text}
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default DashboardSideNavBar
