import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'animate.css'
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Illustration from '../../../assets/images/illustrations/Certification.png'
import gooleLogo from '../../../assets/images/web/google-logo.png'
import mailLogo from '../../../assets/images/web/mail.png'
import oneTimePassword from '../../../assets/images/web/one-time-password.png'
import { Alert, Navbar } from '../../../components'
import { auth } from '../../../utils/firebase'
import { useAuth } from '../../../utils/firebase/auth'
import './style.scss'
const LoginPage = () => {
  const { authUser, isLoading } = useAuth()

  const [loginType, setLoginType] = useState(null)
  const navigate = useNavigate()

  // const loginWithEmailOrPhone = (event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   switch (loginType) {
  //     case 'EMAIL_ONLY':
  //       break
  //     case 'EMAIL_PASSWORD':
  //       break
  //     case 'PHONE':
  //       break
  //     default:
  //       console.log('Application Error. Contact support')
  //   }
  // }

  const signInwithGoogle = async (event) => {
    event.stopPropagation()
    try {
      const { user } = await signInWithPopup(auth, new GoogleAuthProvider())
      console.log(user)
      // toast.success(`Welcome back,  ${user?.displayName || user?.email}.`)
      navigate('/dashboard/profile', { replace: true })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (isLoading || !authUser) return
    // toast.info(
    //   `You are already signed in as ${
    //     authUser?.displayName || authUser?.email
    //   } .`,
    //   { autoClose: 5000 }
    // )
    navigate('/dashboard/profile', { replace: true })
  })

  return (
    <div className="login__home">
      <Navbar />
      <div className="left">
        {/* <Link to="/">
          <div className="logo">
            <img src={logo} alt="KET Academy Logo" />
          </div>
        </Link> */}
        {loginType && (
          <LoginContainer loginType={loginType} setLoginType={setLoginType} />
        )}

        {!loginType && (
          <div className="login__options">
            <h2 className="text-3xl font-bold text-primary my-4 mb-8 text-center">
              Login <span className="text-sm font-semibold">using</span>
            </h2>
            <button onClick={() => setLoginType('EMAIL_ONLY')}>
              <img src={mailLogo} alt="mail"></img>
              Email Only
            </button>
            <button onClick={() => setLoginType('EMAIL_PASSWORD')}>
              <img src={oneTimePassword} alt="pwd"></img>
              Email and Password
            </button>
            <button onClick={signInwithGoogle}>
              <img src={gooleLogo} alt="Google"></img>
              Google
            </button>
            {/* <button onClick={signInwithApple}>
              <img src={appleLogo} alt="Apple"></img>
              Apple
            </button> */}

            {/* <button onClick={() => setLoginType('PHONE')}>
              <img src={phoneLogo} alt="Phone"></img>
              Phone Number
            </button> */}
          </div>
        )}

        <div className="to_login mt-5">
          <span>
            Don't yet have an account?{' '}
            <Link to={'/account/signup'}>Create One.</Link>
          </span>
          <span className="mt-4">
            Forgot Password?{' '}
            <Link to={'/account/recover'}>Recover your account.</Link>
          </span>
        </div>
      </div>

      <div className="right">
        <h1 className="text-3xl font-bold text-center">
          Welcome to KET Academy
        </h1>
        <div className="illustration">
          <img src={Illustration} alt="Illustration" />
        </div>
        <h3 className="text-3xl text-center font-semibold">
          Evolving Education with <br />
          <span className="text-accent"> DIGITAL LEVERAGE</span>
        </h3>
      </div>
    </div>
  )
}

const LoginContainer = ({ loginType, setLoginType }) => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const signInWithEmailOrPhone = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSubmitting(true)

    //TODO redirect the user to dashboard page on login success
    switch (loginType) {
      case 'EMAIL_ONLY':
        {
          const toastId = toast.loading('Sending login link', {
            position: 'top-left',
            autoClose: false,
          })
          try {
            await sendSignInLinkToEmail(auth, email, {
              url: `${window.location.origin}/dashboard`,
              handleCodeInApp: true,
            })
            window.localStorage.setItem('KET-email-for-sign-in', email)
            toast.update(toastId, {
              render: `A sign in link has been sent to ${email} . Check your email to complete login`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
            })
            // Alert.fire({
            //   type: 'Sign In ',
            //   title: <h2>Success!</h2>,
            //   text: `A sign  link has been sent to ${email}. Check your email including the spam folders`,
            // })
          } catch (error) {
            setIsSubmitting(false)
            console.log(error)
            toast.update(toastId, {
              render: error.message || 'Error creating account',
              type: 'error',
              isLoading: false,
              autoClose: 1000,
            })
            // Alert.fire({
            //   type: 'error',
            //   title: <h2>Error!</h2>,
            //   text: error.message || 'Error creating account',
            // })
          }
        }
        break
      case 'EMAIL_PASSWORD':
        //validate name, email and password
        //email is validated by form
        const toastId = toast.loading('Logging you in', {
          position: 'top-left',
          autoClose: false,
        })

        try {
          const { user } = await signInWithEmailAndPassword(
            auth,
            email,
            password
          )
          console.log({ user })
          toast.dismiss(toastId)
          navigate('/dashboard/home', { replace: true })
        } catch (error) {
          console.log(error)
          toast.update(toastId, {
            render: error.message || 'Error logging into account',
            type: 'error',
            isLoading: false,
            autoClose: 1000,
          })
          Alert.fire({
            type: 'error',
            title: <h2>Error!</h2>,
            text: error.message || 'Error logging into account',
          })
        }
        break

      case 'PHONE':
        try {
          const { user } = await signInWithPhoneNumber(auth, phone, password)
          console.log({ user })
          toast.dismiss(toastId)
          navigate('/dashboard/home', { replace: true })
        } catch (error) {
          console.log(error)
          toast.update(toastId, {
            render: error.message || 'Error logging into account',
            type: 'error',
            isLoading: false,
            autoClose: 1000,
          })
          Alert.fire({
            type: 'error',
            title: <h2>Error!</h2>,
            text: error.message || 'Error logging into account',
          })
        }
        break
      default:
        alert('Application Error')
    }
  }

  return (
    <div className="form__container" style={{ display: 'block' }}>
      <span className="back__button" onClick={() => setLoginType(null)}>
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
      </span>
      <div className="title__text">
        <h2>Login</h2>
      </div>
      <form method="POST" onSubmit={signInWithEmailOrPhone}>
        {loginType !== 'PHONE' ? (
          <div className="field">
            <label htmlFor="">Your Email </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ex: kinlo.tangiri@gmail.com"
              required
            />
          </div>
        ) : (
          <div className="field">
            <label htmlFor="">Your Phone Number </label>
            <span className="flag"></span>
            <input
              type="tel"
              name="telephone"
              value={phone}
              onChange={(event) =>
                setPhone(event.target.value.replace(/[^0-9]/g, ''))
              }
              minLength={9}
              maxLength={9}
              placeholder="Ex: 675342312"
              required
            />
          </div>
        )}

        {loginType === 'EMAIL_PASSWORD' && (
          <div className="field">
            <label htmlFor="">Your Password</label>
            <input
              className="login-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter a your password"
              minLength={8}
              maxLength={20}
              required
            />
            <FontAwesomeIcon
              className="show-pass"
              onClick={(_) => setShowPassword((show) => !show)}
              icon={showPassword ? faEyeSlash : faEye}
            />
          </div>
        )}

        <div className="field">
          <button
            type="submit"
            className="relative mt-4"
            disabled={isSubmitting}
          >
            Continue with Us
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginPage
