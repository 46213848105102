import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import React, { useState } from 'react'
import {
  categories,
  concentrations,
  levels,
  subSystems,
  subjects,
} from '../../../utils/constants'
import { firestore, storage } from '../../../utils/firebase'
import { useAuth } from '../../../utils/firebase/auth'
import Alert from '../../Alert'
import './index.scss'

// Main component for the Admin Dashboard Home page
const AdminDashboardHome = function () {
  return (
    <main>
      <TemporaryUploadCard />
      <StatisticsCard />
      <PostCard />
    </main>
  )
}

const StatisticsCard = function () {
  return <div></div>
}

const PostCard = function () {
  return <section></section>
}

// Temporary card for uploading resources
const TemporaryUploadCard = () => {
  // State management for form data and upload status
  const [formData, setFormData] = useState({
    concentration: '',
    system: '',
    level: '',
    subject: '',
    title: '',
    topic: '',
    resourceType: categories.PDF,
    pdfFile: null,
    youtubeLink: '',
    keywords: '',
  })
  const [isUploading, setIsUploading] = useState(false)
  const { authUser } = useAuth() // Authentication context

  // Handles input changes and updates the state accordingly
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : value,
    }))
  }

  // Handles the form submission for resource uploads
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsUploading(true)

    try {
      // Security check to ensure the user is logged in before uploading
      if (!authUser) {
        throw new Error('You must be logged in to upload resources.')
      }

      // Date check to restrict uploads past a certain date
      const currentDate = new Date()
      const deadlineDate = new Date(currentDate.getFullYear(), 11, 25) // Month is 0-indexed
      if (currentDate > deadlineDate) {
        throw new Error(
          'Resource uploads are no longer accepted after December 25th.'
        )
      }

      let resourceUrl = ''

      // Upload logic based on the type of resource
      if (formData.resourceType === categories.PDF && formData.pdfFile) {
        const storageRef = ref(storage, `resources/${formData.pdfFile.name}`)
        await uploadBytes(storageRef, formData.pdfFile)
        resourceUrl = await getDownloadURL(storageRef)
      } else if (formData.resourceType === categories.YOUTUBE) {
        resourceUrl = formData.youtubeLink
      }

      // Data preparation for Firestore
      const resourceData = {
        concentration: formData.concentration,
        system: formData.system,
        level: formData.level,
        subject: formData.subject,
        title: formData.title.toLowerCase(),
        topic: formData.topic.toLowerCase(),
        resourceType: formData.resourceType,
        resourceUrl: resourceUrl,
        uploadedBy: authUser.uid,
        createdAt: serverTimestamp(),
        keywords: formData.keywords.split(' ').map((kw) => kw.toLowerCase()), // Process keywords for consistency
      }

      // Firestore document creation
      await addDoc(collection(firestore, 'resources'), resourceData)

      Alert.fire({
        // Alert configuration for successful upload
        icon: 'success',
        title: 'Upload successful',
        text: 'Your resource has been successfully uploaded.',
      })
    } catch (error) {
      // Error handling
      Alert.fire({
        icon: 'error',
        title: 'Upload failed',
        text: error.message,
      })
    } finally {
      setIsUploading(false) // Reset upload state
    }
  }

  // Render method for the upload form
  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Upload Resource</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="concentration"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            🎯 Concentration:
          </label>
          <select
            id="concentration"
            name="concentration"
            value={formData.concentration}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Concentration</option>
            {concentrations.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="system"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            🔄 System:
          </label>
          <select
            id="system"
            name="system"
            value={formData.system}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select System</option>
            {subSystems.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="level"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            📊 Level:
          </label>
          <select
            id="level"
            name="level"
            value={formData.level}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Level</option>
            {formData.system &&
              levels[formData.system] &&
              levels[formData.system].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            📚 Subject:
          </label>
          <select
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Subject</option>
            {Object.entries(subjects).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            📝 Title of Resource:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="topic"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            📌 Best Subject Topic for this Resource:
          </label>
          <input
            type="text"
            id="topic"
            name="topic"
            value={formData.topic}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="resourceType"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Resource Type:
          </label>
          <select
            id="resourceType"
            name="resourceType"
            value={formData.resourceType}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value={categories.PDF}>📄 PDF Document</option>
            <option value={categories.YOUTUBE}>🎥 YouTube Video</option>
          </select>
        </div>

        {formData.resourceType === categories.PDF && (
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <label
              htmlFor="pdfFile"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              📄 Upload PDF File:
            </label>
            <input
              type="file"
              id="pdfFile"
              name="pdfFile"
              accept=".pdf"
              onChange={handleInputChange}
              required
              disabled={isUploading}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        )}

        {formData.resourceType === categories.YOUTUBE && (
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <label
              htmlFor="youtubeLink"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              🎥 YouTube Video Link:
            </label>
            <input
              type="url"
              id="youtubeLink"
              name="youtubeLink"
              value={formData.youtubeLink}
              onChange={handleInputChange}
              placeholder="https://www.youtube.com/watch?v=..."
              required
              disabled={isUploading}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        )}

        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <label
            htmlFor="keywords"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            🏷️ Keywords (8-20, separated by spaces):
          </label>
          <textarea
            id="keywords"
            name="keywords"
            value={formData.keywords}
            onChange={handleInputChange}
            required
            disabled={isUploading}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button
          type="submit"
          disabled={isUploading}
          className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white ${
            isUploading
              ? 'bg-primary cursor-not-allowed'
              : 'bg-primary hover:bg-primary-alt focus:outline-none transition-all'
          }`}
        >
          {isUploading ? (
            <span className="flex items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Uploading...
            </span>
          ) : (
            'Upload Resource'
          )}
        </button>
      </form>
    </div>
  )
}

export default AdminDashboardHome
