import {
  faArrowLeft,
  faArrowRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState, useCallback } from 'react'

import VanessaImage from '../../../assets/images/people/VanessaLogan.jpg'
import RonalsImage from '../../../assets/images/people/Ronald.jpg'
import CatherineImage from '../../../assets/images/people/Katherinetestimonial.jpg'
import AlainImage from '../../../assets/images/people/Alain111.png'
import LeslyImage from '../../../assets/images/people/TasangLesley.jpeg'

// Stylesheet import
import './index.scss'

// Testimonials component definition
const Testimonials = () => {
  // Mock data for testimonials
  const mockdata = [
    {
      stars: 4,
      text: 'I applied as an ambassador to KET Academy because I wanted to give back and help students who are facing the same educational challenges I encountered. KET Academy empowered me with the opportunity to make a real impact, supporting thousands of students who are academically hungry for knowledge. I proudly dedicate my academic success to KET Academy and the guidance it provided.',
      name: 'Kamaha Ronald Parfait',
      school: 'University Student',
      image: RonalsImage,
    },
    {
      stars: 5,
      text: 'I am extremely grateful to KET Academy for the support I received during my preparation for the GCE A/L, particularly with the practical sessions. The high-quality YouTube videos helped make my last-minute revision more effective. KET Academy offers these valuable resources completely free of charge, demonstrating a true commitment to helping students succeed.',
      name: 'Enangue N. Catherine',
      school: 'University of Buea, FHS',
      image: CatherineImage,
    },
    {
      stars: 4,
      text: 'KET Academy not only provides academic resources but also teaches leadership and collaboration. My experience as both a student and an ambassador was incredibly enriching. KET Academy taught me how to organize my time and responsibilities while cooperating with other students and ambassadors, building a strong community of learners dedicated to growth and success.',
      name: 'Tonlie Alian',
      school: 'G.B.H.S Etoug-Egbe, USS',
      image: AlainImage,
    },
    {
      stars: 4,
      text: 'Being a KET Ambassador and a student has profoundly changed my perspective on giving back to society. It has instilled in me a sense of selflessness and a deeper understanding of what it means to live for others. KET Academy’s mentorship and community-building efforts have inspired me to become a better leader and role model for my peers.',
      name: 'Berinyuy A. Vanessa',
      school: 'University Student',
      image: VanessaImage,
    },
    {
      stars: 5,
      text: 'Serving as a KET Ambassador has been a life-changing experience. Working with other passionate youth has taught me how to lead with empathy, navigate different perspectives, and collaborate effectively. Through this experience, I’ve developed valuable leadership skills and learned how to manage diverse teams to achieve our shared goal of helping students succeed.',
      name: 'Tasang Lesly',
      school: 'University Student',
      image: LeslyImage,
    },
    {
      stars: 4,
      text: 'Preparing for the GCE with KET Academy was a game-changer for me. The resources, including past questions and solutions, were crucial to my success. I became an ambassador so that I could pay it forward, helping other students by providing them the same valuable resources and support that KET Academy gave me.',
      name: 'Nkengbeza Derick',
      school: 'Prospective University Student',
      image: 'path/to/image6.jpg',
    }
    
  ]
  const testimonialBox = useRef(null)
  const [testimonials] = useState(mockdata)

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle scrolling to the next or previous testimonial
  const scrollToNextPrev = useCallback((direction) => {
    const testimonialsCount = testimonials.length;
    const itemWidth = testimonialBox.current?.firstChild?.offsetWidth || 400; // Dynamically get the width of a testimonial or default to 400px
    const totalWidth = itemWidth * testimonialsCount;

    let nextItemPosition = (testimonialBox.current?.scrollLeft || 0) + direction * itemWidth;

    // Wrap around logic for infinite scrolling
    if (nextItemPosition >= totalWidth) {
      nextItemPosition = 0; // Reset to start if moving right from the last item
    } else if (nextItemPosition < 0) {
      nextItemPosition = totalWidth - itemWidth; // Move to the last item if moving left from the first item
    }

    testimonialBox.current?.scrollTo({
      left: nextItemPosition,
      behavior: 'smooth',
    });

    // Update current index for active testimonial
    setCurrentIndex((prevIndex) => {
      return (prevIndex + direction + testimonialsCount) % testimonialsCount;
    });
  }, [testimonials.length]);

  // Effect to auto-scroll testimonials every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      scrollToNextPrev(1);
    }, 20000);

    return () => clearInterval(interval);
  }, [scrollToNextPrev]);

  return (
    <section className="testimonials-section py-8 bg-primary block relative h-auto">
      <div>
        <h3 className="font-bold text-2xl text-center text-secondary">
          What Our Students & Ambassador Say
        </h3>
      </div>

      <div
        className="testimonials flex flex-row overflow-x-auto px-0"
        ref={testimonialBox}
      >
        {/* Render testimonials twice for seamless infinite scrolling */}
        {[...testimonials, ...testimonials].map(({ stars, text, name, school, image }, i) => (
          <Testimonial
            stars={stars}
            text={text}
            name={name}
            school={school}
            key={`testimonial-${i}`}
            active={i % testimonials.length === currentIndex} // Adjust active logic for duplicated items
            image={image}
          />
        ))}
      </div>
      <div className="controls text-center min-w-min mx-auto my-4">
        
        <button
          className="inline-flex w-10 h-10 p-3 mr-4 justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={() => scrollToNextPrev(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {testimonials.map((_, i) => (
          <span
            key={i}
            className={`inline-block w-3 h-3 mx-2 rounded-full ${
              i === currentIndex ? 'bg-accent' : 'bg-secondary'
            } my-auto`}
          ></span>
        ))}

        <button
          className="inline-flex w-10 h-10 p-3 ml-4 justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={() => scrollToNextPrev(1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </section>
  )
}

const Testimonial = ({ stars, text, name, school, image, active }) => {
  const defaultImage = 'https://picsum.photos/50'; // URL for a random 50x50 image from Lorem Picsum

  return (
    <div className={`bg-secondary py-2 px-4 testimonial ${active ? 'active' : ''}`}>
      <img
        src={image || defaultImage} // Use the provided image or fallback to the random filler image
        alt={`${name}'s testimonial`}
        className="testimonial-image"
      />
      <div className="stars my-2 flex">
        {Array.from({ length: stars }, (_, i) => (
          <FontAwesomeIcon icon={faStar} className="text-accent mr-1" key={i} />
        ))}
        {Array.from({ length: 5 - stars }, (_, i) => (
          <FontAwesomeIcon
            icon={faStar}
            className="text-neutral-alt-3 mr-1"
            key={i}
          />
        ))}
      </div>
      <p className="text">{text}</p>
      <h5 className="mt-2 text-neutral-alt-2 font-bold text-right">{name}</h5>
      <h6 className="font-normal text-right">{school}</h6>
    </div>
  );
};

export default Testimonials
