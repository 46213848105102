import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInWithPhoneNumber,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../../utils/firebase'

import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'animate.css'
import { toast } from 'react-toastify'
import Illustration from '../../../assets/images/illustrations/Boy-attending-video-lecture.png'
import googleLogo from '../../../assets/images/web/google-logo.png'
import mailLogo from '../../../assets/images/web/mail.png'
import oneTimePassword from '../../../assets/images/web/one-time-password.png'
import { Alert, Navbar } from '../../../components'
import { useAuth } from '../../../utils/firebase/auth'
import './style.scss'

const SignUpPage = () => {
  // const SignUpTypes = new Set('EMAIL_ONLY', 'EMAIL_PASSWORD', 'PHONE')
  const { authUser, isLoading } = useAuth()
  const [signUpType, setSignUpType] = useState(null)
  const navigate = useNavigate()

  const signUpwithGoogle = async (event) => {
    event.stopPropagation()
    try {
      const { user } = await signInWithPopup(auth, new GoogleAuthProvider())
      toast.success(
        `Welcome, ${
          user?.displayName || 'User'
        }! Complete your profile ont student dashbaord.`
      )
      // toast.success(
      //   `You have successfully created your KET Student Account,${
      //     user?.displayName || 'No name'
      //   } . Head over to your dashboard and complete your profile.`
      // )
      navigate('/dashboard/profile', { replace: true })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (isLoading || !authUser) return
    toast.info(
      `You are already signed in as ${
        authUser?.displayName || authUser?.email
      } .`,
      { autoClose: 5000 }
    )
    navigate('/dashboard/profile', { replace: true })
  })

  return (
    <div className="signup__home">
      <Navbar />
      <div className="left">
        {/* <Link to="/">
          <div className="logo">
            <img src={logo} alt="KET Academy Logo" />
          </div>
        </Link> */}

        {signUpType && (
          <SignUpContainer
            signUpType={signUpType}
            setSignUpType={setSignUpType}
          />
        )}
        {!signUpType && (
          <div className="signup__options">
            <h2 className="text-3xl font-bold text-primary my-4 mb-8 text-center">
              Sign Up <span className="text-sm font-semibold">with</span>
            </h2>
            <button onClick={() => setSignUpType('EMAIL_ONLY')}>
              <img src={mailLogo} alt="mail"></img>
              Email Only
            </button>
            <button onClick={() => setSignUpType('EMAIL_PASSWORD')}>
              <img src={oneTimePassword} alt="pwd"></img>
              Email and Password
            </button>
            <button onClick={signUpwithGoogle}>
              <img src={googleLogo} alt="Google"></img>
              Google
            </button>
            {/* <button onClick={signUpwithApple}>
              <img src={appleLogo} alt="Apple"></img>
              Apple
            </button> */}
            {/* <button onClick={() => setSignUpType('PHONE')}>
              <img src={phoneLogo} alt="Phone"></img>
              Phone Number
            </button> */}
          </div>
        )}
        <div className="to_signup mt-5">
          <span>
            Already have an account? <Link to={'/account/login'}>Login</Link>
          </span>
        </div>
      </div>

      <div className="right">
        <h1 className="text-3xl font-bold text-center">
          Welcome to KET Academy
        </h1>

        <div className="illustration">
          <img src={Illustration} alt="Illustration" />
          <div></div>
        </div>
        <h3 className="text-3xl text-center font-semibold">
          Evolving Education with <br />
          <span className="text-accent"> DIGITAL LEVERAGE</span>
        </h3>
      </div>
    </div>
  )
}

const SignUpContainer = ({ signUpType, setSignUpType }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const verifyRecaptcha = () => {
    // const app = initializeApp(firebaseConfig)
    // const auth = getAuth(app)
    // window.recaptchaVerifier = new RecaptchaVerifier(
    //   'recaptcha-container',
    //   {
    //     size: 'invisible',
    //     callback: (response) => {
    //       console.log(response)
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       // ...
    //     },
    //   },
    //   auth
    // )
    let recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {},
      auth
    )
    console.log(recaptchaVerifier)
  }
  const signUpWithEmailOrPhone = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSubmitting(true)

    //TODO redirect the user to login page on account creation success
    switch (signUpType) {
      case 'EMAIL_ONLY':
        {
          const toastId = toast.loading('Creating your account', {
            position: 'top-left',
            autoClose: false,
          })
          try {
            await sendSignInLinkToEmail(auth, email, {
              url: `${window.location.origin}/dashboard`,
              handleCodeInApp: true,
            })
            window.localStorage.setItem('KET-email-for-sign-in', email)
            toast.update(toastId, {
              render: `A sign in link has been sent to,${email} . Check your email to complete login`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
            })
            // Alert.fire({
            //   type: 'Sign In ',
            //   title: <h2>Success!</h2>,
            //   text: `A sign  link has been sent to ${email}. Check your email including the spam folders`,
            // })
          } catch (error) {
            setIsSubmitting(false)
            console.log(error)
            toast.update(toastId, {
              render: error.message || 'Error creating account',
              type: 'error',
              isLoading: false,
              autoClose: 1000,
            })
            // Alert.fire({
            //   type: 'error',
            //   title: <h2>Error!</h2>,
            //   text: error.message || 'Error creating account',
            // })
          }
        }
        break
      case 'PHONE':
        {
          if (isNaN(parseFloat(phone))) {
            Alert.fire({
              icon: 'error',
              type: 'error',
              title: <h2>Error!</h2>,
              text: 'Your phone number can contain ONLY digits',
            }).then(() => window.location.reload())
            return
          }
          toast.loading('Creating your account', {
            position: 'top-left',
            autoClose: false,
          })

          // console.log('cap')
          verifyRecaptcha()
          const appVerifier = window.recaptchaVerifier
          // console.log('tcha')
          // new RecaptchaVerifier(
          //   'recaptcha-container',
          //   {
          //     size: 'normal',
          //     callback: (response) => {
          //       console.log(response)
          //       // reCAPTCHA solved, allow signInWithPhoneNumber.
          //       // ...
          //     },
          //     'expired-callback': () => {
          //       // Response expired. Ask user to solve reCAPTCHA again.
          //       // ...
          //     },
          //   },
          //   auth
          // )
          // new RecaptchaVerifier(auth, 'verify-container', {
          //   size: 'invisible',
          //   // callback: (response) => {
          //   //   // reCAPTCHA solved, allow signInWithPhoneNumber.
          //   //   // onSignInSubmit()
          //   // },
          // })

          //window.recaptchaVerifier = appVerifier

          try {
            const confirmationResult = await signInWithPhoneNumber(
              auth,
              '16092553913',
              appVerifier
            )
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).

            //TODO window.confirmationResult = confirmationResult
            toast.update({
              render: `A confirmation code has been sent to <<${phone}>>`,
              type: 'info',
              isLoading: false,
              autoClose: 3000,
            })
            //confirm OTP
            const code = await Alert.fire({
              title: 'Enter the Code sent to you number',
              input: 'number',
              inputLabel: 'Confirmation Code',
              inputPlaceholder: 'XXXXXX',
            })
            const { user } = confirmationResult.confirm(code)
            console.log({ user })
          } catch (error) {
            console.log(error)
          }
        }
        break

      case 'EMAIL_PASSWORD':
        //validate name, email and password
        //email is validated by form
        const toastId = toast.loading('Creating your account', {
          position: 'top-left',
          autoClose: false,
        })

        //TODO let inputError = null

        try {
          const { user } = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          )
          await sendEmailVerification(user)
          await updateProfile(user, { displayName: name })
          console.log(user)
          toast.success(
            `Welcome, ${
              user?.displayName || 'User'
            }! Complete your profile ont student dashbaord.`
          )
          Alert.fire({
            type: 'success',
            title: <h2>Success!</h2>,
            text: `A verification link has been sent to ${email}. Verify your email`,
          }).then(() => navigate('/account/login', { replace: true }))
          toast.success(
            `A verification link has been sent to <<${email}>>. Verify your email`,
            { autoClose: 1000 }
          )
        } catch (error) {
          setIsSubmitting(false)
          console.log(error)
          toast.update(toastId, {
            render: error.message || 'Error creating account',
            type: 'error',
            isLoading: false,
            autoClose: 1000,
          })
          Alert.fire({
            type: 'error',
            title: <h2>Error!</h2>,
            text: error.message || 'Error creating account',
          })
        }
        break

      default:
        alert('Application Error')
    }
  }

  return (
    <div className="form__container" style={{ display: 'block' }}>
      <span className="back__button" onClick={() => setSignUpType(null)}>
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
      </span>
      <div className="title__text">
        <h2>Sign Up</h2>
      </div>

      <form method="POST" onSubmit={signUpWithEmailOrPhone}>
        <div className="field">
          <label htmlFor="">Your Full Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ex: Kinlo Ephriam Tangiri"
            minLength={8}
            maxLength={30}
            required
          />
        </div>
        {signUpType !== 'PHONE' ? (
          <div className="field">
            <label htmlFor="">Your Email </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ex: kinlo.tangiri@gmail.com"
              required
            />
          </div>
        ) : (
          <div className="field">
            <label htmlFor="">Your Phone Number</label>
            <span className="flag"></span>
            <input
              type="tel"
              name="telephone"
              value={phone}
              onChange={(event) =>
                setPhone(event.target.value.replace(/[^0-9]/g, ''))
              }
              minLength={9}
              maxLength={9}
              placeholder="Ex: 675342312"
              required
            />
          </div>
        )}

        {signUpType === 'EMAIL_PASSWORD' && (
          <div className="field">
            <label htmlFor="">Your Password</label>
            <input
              className="su-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter a strong password"
              minLength={8}
              maxLength={20}
              required
            />
            {/* <div id="recaptcha-container"></div> */}

            <FontAwesomeIcon
              className="show-pass"
              onClick={(_) => setShowPassword((show) => !show)}
              icon={showPassword ? faEyeSlash : faEye}
            />
          </div>
        )}

        <div className="field">
          <button
            type="submit"
            className="relative mt-4"
            disabled={isSubmitting}
          >
            Join Us
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignUpPage
